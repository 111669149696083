import { AxiosError } from 'axios'
import http from './http'

export const getConfig = async (tenantUuid: string) => {
  try {
    const { data } = await http.get('/config', {
      headers: { 'X-NSft-Seven-Tenant-ID': tenantUuid }
    })

    return data
  } catch (e) {
    const error = e instanceof AxiosError ? e?.response?.data : e

    console.error(e)

    return {
      error: true,
      message: error.message,
      ...error
    }
  }
}


export const login = async (token: string) => {
  try {
    const { data } = await http.get('/login', {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    const error = e instanceof AxiosError ? e?.response?.data : e

    console.error(e)

    return {
      error: true,
      message: error.message,
      ...error
    }
  }
}

export default { login, getConfig }
