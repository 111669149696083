import GGSlave from '@/services/integrations/seven-gravity-gateway/'
import nativeWebview from '@/services/integrations/webview'
import nativeWKWebview from '@/services/integrations/wkwebview'

const integrator = {
  instance: {} as typeof GGSlave | typeof nativeWebview | typeof nativeWKWebview,

  init(integrationType: string) {
    if (integrationType === 'gravityGateway') {
      this.instance = GGSlave
    } else if (integrationType === 'webview') {
      this.instance = nativeWebview
    } else if (integrationType === 'wkwebview') {
      this.instance = nativeWKWebview
    } else {
      console.error(`Unknown integration implementation: ${integrationType}`)
    }

    return this.instance?.init()
  },

  notifyAppLoaded() {
    return this.instance?.notifyAppLoaded()
  },

  requireUserLogin() {
    return this.instance?.requireUserLogin()
  }
}

export default integrator
