import mitt, { type EventType } from 'mitt'

const emitter = mitt()

export default {
  publish: (event: EventType, payload: unknown) => {
    emitter.emit(event, payload)
  },
  subscribe: (event: EventType, callback: <T>(message: T) => unknown) => {
    emitter.on(event, (message) => callback(message))
  }
}
