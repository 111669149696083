import * as Sentry from '@sentry/vue'
import type { App as AppType } from 'vue'

const initializeErrorTracker = (app: AppType) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_APP_URL, /^\//]
      })
    ],
    environment: import.meta.env.MODE,
    logErrors: true,
    tracesSampleRate: 0.3
  })
}

export default initializeErrorTracker
