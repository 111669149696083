import { slave as Gateway } from '@nsftx/seven-gravity-gateway'
import eventBus from '@/utility/eventBus'

let instance: typeof Gateway

export default {
  init() {
    return new Promise((resolve) => {
      instance = Gateway({
        allowedOrigins: import.meta.env.VITE_APP_ALLOWED_ORIGINS,
        slaveId: import.meta.env.VITE_APP_SLAVE_ID,
        load: (message: { data: unknown }) => {
          resolve(message.data)
        }
      })

      this.setListeners()
    })
  },

  setListeners() {
    instance?.subscribe(
      'User.AuthorizationChanged',
      (message: { data: { auth: { token: string | null } } }) => {
        eventBus.publish('User.AuthorizationChanged', message.data)
      }
    )
  },

  notifyAppLoaded() {
    instance?.sendMessage({
      action: 'Slave.Loaded'
    })
  },

  requireUserLogin() {
    instance?.sendMessage({
      action: 'User.LoginRequired'
    })
  }
}
