<script setup lang="ts">
import { ref, onBeforeMount, reactive } from 'vue'
import { useUrlSearchParams, useEventListener } from '@vueuse/core'
import { login, getConfig } from '@/api/osaiIntegrator'
import integrator from '@/services'
import eventBus from '@/utility/eventBus'
import { AlertVariant, AlertPosition, SCAlert } from '@nsftx/seven-components'
import type { AuthorizationChangedMessageData } from '@/types'

const {
  integrationType: integrationTypeSearchParam,
  language: languageSearchParam,
  tenantUuid: tenantUuidSearchParam
} = useUrlSearchParams('history')

const integrationType =
  integrationTypeSearchParam instanceof Array
    ? integrationTypeSearchParam[0]
    : integrationTypeSearchParam
const language = languageSearchParam instanceof Array ? languageSearchParam[0] : languageSearchParam
const tenantUuid =
  tenantUuidSearchParam instanceof Array ? tenantUuidSearchParam[0] : tenantUuidSearchParam
const isIntegration = !!integrationType
const sevenToken = ref<string | null>('')
const playerUuid = ref('')
const sessionToken = ref('')
const iframeURL = ref('')
const iframeElement = ref<HTMLIFrameElement | null>(null)
const errorNotification = reactive({
  active: false,
  text: '',
  position: AlertPosition.Bottom,
  variant: AlertVariant.Negative
})
const defaultErrorMessage = 'Something went wrong. Please reload or contact customer support'

useEventListener(window, 'message', (e) => {
  console.log('[Osai Client] message event:', e)

  if (e.data.event_id === 'loaded') {
    // send playerUuid and sessionToken to iframe if user is logged in
    if (playerUuid.value && sessionToken.value) {
      notifyIframeAboutAuthorizationChange('login')
    }
  }

  if (e.data.event_id === 'sign_in') {
    requestLogin()
  }
})

onBeforeMount(async () => {
  const { customerId } = await getConfig(tenantUuid)

  if (isIntegration) {
    await setupSevenIntegration()
  }
  setIframeUrl(customerId)
})

const setupSevenIntegration = async () => {
  const {
    auth: { token }
  } = (await integrator.init(integrationType)) as {
    auth: { token: string }
  }

  sevenToken.value = token
  notifyAppLoad()

  if (sevenToken.value) {
    await loginAndSetPlayerUuidAndSessionToken()
  }

  setListeners()
}

const setIframeUrl = (customerId: string) => {
  const lang = language?.split('-')[0]

  const searchParams = new URLSearchParams({ customer_id: customerId })
  if (lang) searchParams.set('lang', lang)

  iframeURL.value = `${import.meta.env.VITE_APP_OSAI_INTEGRATION_BASE_URL}/#/bet?${searchParams}`
}

const loginAndSetPlayerUuidAndSessionToken = async () => {
  const {
    playerUuid: uuid,
    sessionToken: token,
    message,
    error
  } = await login(sevenToken.value as string)

  if (error) {
    showErrorNotification(error, message)
    resetPlayerUuidAndSessionToken()
    return
  }

  setPlayerUuidAndSessionToken(uuid, token)
}

const notifyAppLoad = () => {
  integrator.notifyAppLoaded()
}

const requestLogin = () => {
  integrator.requireUserLogin()
}

const setListeners = () => {
  eventBus.subscribe('User.AuthorizationChanged', (message) =>
    handleAuthorizationChangeMessage(message as AuthorizationChangedMessageData)
  )
}

const setPlayerUuidAndSessionToken = (uuid: string, token: string) => {
  playerUuid.value = uuid
  sessionToken.value = token
}

const resetPlayerUuidAndSessionToken = () => {
  playerUuid.value = ''
  sessionToken.value = ''
}

const handleAuthorizationChangeMessage = async (data: AuthorizationChangedMessageData) => {
  sevenToken.value = data.auth.token

  if (sevenToken.value) {
    await loginAndSetPlayerUuidAndSessionToken()
    notifyIframeAboutAuthorizationChange('login')
  } else {
    resetPlayerUuidAndSessionToken()
    notifyIframeAboutAuthorizationChange('logout')
  }
}

const notifyIframeAboutAuthorizationChange = (eventType: 'login' | 'logout') => {
  if (!iframeElement.value) return
  iframeElement.value?.contentWindow?.postMessage(
    {
      type: eventType,
      data: {
        sessionToken: sessionToken.value
      }
    },
    `${import.meta.env.VITE_APP_ALLOWED_ORIGINS}`
  )
}

const showErrorNotification = (error: boolean, message: string) => {
  errorNotification.active = error
  errorNotification.text = message ? message : defaultErrorMessage
}
</script>

<template>
  <iframe
    v-if="iframeURL"
    ref="iframeElement"
    class="osai-iframe"
    :src="iframeURL"
    :data-trigger-authorize="true"
  ></iframe>
  <SCAlert
    v-model="errorNotification.active"
    :timeout="null"
    :variant="errorNotification.variant"
    :position="errorNotification.position"
  >
    <template #text>{{ errorNotification.text }}</template>
  </SCAlert>
</template>

<style scoped lang="scss">
.osai-iframe {
  width: 100%;
  min-height: 100vh;
}
</style>
